<script>
import Swal from "sweetalert2";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
// import DisplayCmp from "./components/DisplayCmp";
export default {
  components: {  Dataset, DatasetItem, /* DatasetInfo,*/DatasetPager, /* DatasetSearch,*/ DatasetShow  },
  props :{reloadTable : undefined},
  data() {
    return {
      tableData: [],
      title: "Rôles",
      roles: [],
      items: [
        {
          text: "Paramètres",
          href: "javascript:void(0)",
        },
        {
          text: "Rôles",
          active: true,
        },
      ],
      cols: [
         {
          name: "Désignation ",
          field: "ref",
          sort: ''
        },
        {
          name: "Permissions ",
          field: "Permission",
          sort: ''
        },
      ],
      selectedRows:[],
      selectedIndex:-1,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
    };
  },
  computed: {
    rows() {
      return this.roles.length;
    },
  },
  mounted() {
    this.totalRows = this.roles.length;
    this.refreshRolesTable();
  },
  watch:{
    reloadTable :{
      handler(val){
        if(val){
          this.refreshRolesTable();
        }
      }
    }
  },
  methods: {
    
    selectRow(row,index){
      if(this.selectedIndex == index) {
        this.selectedRows  = []; 
        this.selectedIndex = -1;
        this.$emit('selectedOrders',[]);
      }
      else {
        this.selectedRows  = [row];
        this.selectedIndex = index;
        this.$emit('selectedOrders',this.selectedRows)
      }
    },
    deleteRole(roleName, roleUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le rôle " + roleName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/system/roles/delete/" + roleUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                  _this.refreshRolesTable();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    refreshRolesTable() {
      this.$http
        .post("/system/roles/list")
        .then((res) => {
          this.roles = res.data.original.data;
          this.$emit('reloadTableDone',false)
          this.selectedRows  = []; 
          this.selectedIndex = -1;
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },

};
</script>

<template>
  <div class="table-responsive mb-0 shadow">
      <!-- <table class="table table-centered table-nowrap"> -->
      <dataset v-slot="{ ds }" :ds-data="roles" >
        <div class="row">
          <div class="col-md-6 mb-2 mb-md-0">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="">
              <table class="table table-hover d-md-table">
                <thead>
                  <tr>
                    <th v-for="(th) in cols" :key="th.field">
                      {{ th.name }} 
                    </th>
                  </tr>
                </thead>
                <dataset-item tag="tbody">
                  <template #default="{ row, rowIndex }">
                    <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                      <td><router-link
                        :to="{
                          name: 'settings.roles.permissions',
                          params: { uid: row.uuid , name:row.name},
                        }"
                        >{{ row.display_name }}</router-link
                      ></td>
                      <td> <router-link
                        :to="{
                          name: 'settings.roles.permissions',
                          params: { uid: row.uuid },
                        }"
                        class="mr-3 text-primary"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Permissions"
                        data-original-title="Edit"
                        ><i class="bx bx-cog font-size-18"></i
                      ></router-link></td>
                    </tr>
                  </template>
                </dataset-item>
              </table>
            </div>
          </div>
        </div>
        <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
          <dataset-show :ds-show-entries="5" />
          <dataset-pager />
        </div>
      </dataset>
  </div>
</template>